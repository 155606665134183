/* Styling for top nav bar */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

body {
  margin-top: -30px;
  background: linear-gradient(rgb(227, 227, 227), rgb(168, 168, 168));
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h5 {
  padding-top: 20px;
  text-shadow: 3px 3px 3px #450808;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-right: 20px;
  /* font-size: 6vw; */
  /* font-size: 20px; */
}

#navC {
  color: white;
  text-decoration: none;
}

#navC:hover {
  color: rgb(0, 0, 0);
  font-weight: 600;
}

#navH5 {
  text-decoration: none;
  margin-left: 0.5%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 5%;
  width: 100%;
  background-color: maroon;
  color: white;
  text-align: center;
}
